/* Success.css */
.success-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.success-container h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.success-container p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.success-container button {
  display: inline-block;
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.success-container button:hover {
  background-color: #0056b3;
}

.success-container h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.success-container ul {
  list-style-type: none;
  padding: 0;
}

.success-container ul li {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

/* Styles for the transparent toggle button */
.toggle-button {
  background-color: transparent;
  border: 1px solid #007bff;
  color: #007bff;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: color 0.3s ease, border-color 0.3s ease;
  position: absolute;
  top: 20px;
  left: 20px;
}

.toggle-button:hover {
  color: #0056b3;
  border-color: #0056b3;
}

.order-summary {
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .success-container {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .success-container {
    padding: 10px;
  }

  .success-container h1 {
    font-size: 1.8rem;
  }

  .success-container p {
    font-size: 1rem;
  }

  .success-container button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .success-container h2 {
    font-size: 1.3rem;
  }

  .success-container ul li {
    font-size: 1rem;
  }
}
:root {
  --background-color: #ffffff;
  --text-color: #000000;
}

[data-theme='dark'] {
  --background-color: #121212;
  --text-color: #ffffff;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}
.success-container {
  padding: 20px;
}

.toggle-button {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--text-color);
  padding: 10px;
  cursor: pointer;
}