body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #282c34;
    padding: 1rem;
    color: white;
  }
  
  nav h1 {
    margin: 0;
  }
  
  nav div a {
    margin-left: 1rem;
    color: white;
    text-decoration: none;
  }
  
  nav div a:hover {
    text-decoration: underline;
  }
  
  input, button {
    margin: 0.5rem;
  }
  