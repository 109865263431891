.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ecc9c9;
}

.price-bar {
  display: flex;
  justify-content: center;
  background-color: #032952;
  padding: 7px 0;
  color: white;
}

.price-item {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.coin-image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1200px;
  padding: 20px;
  text-align: center;
  background-color: #4677a8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin: auto;
  margin-top: 20px;
}

.home-text {
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  line-height: 1.4;
  color: #333;
}

.button-group {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.custom-button {
  background-color: #032952;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #27d845;
}

.banner {
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  background-color: #032952;
  padding: 20px;
  border-radius: 10px;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  color: #d7b440;
}

.products-section {
  width: 100%;
  max-width: 1200px;
  margin-top: 40px;
  border-radius: 30px;
  background-color: #b69733;
  color: #d7b440;
}

.product-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.product-item {
  width: 300px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  text-align: center;
}

.product-image {
  max-width: 150px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
}

.product-info {
  padding: 10px;
}

.add-to-cart-button {
  background-color: #032952;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #27d845;
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background-color: #032952;
  color: white;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-title {
  font-size: 24px;
}

.sidebar-close {
  cursor: pointer;
  font-size: 20px;
}

.sidebar-content {
  margin-top: 20px;
}

.sidebar-product {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-product-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.sidebar-product-info {
  flex-grow: 1;
}

.sidebar-product-name {
  font-size: 18px;
}

.sidebar-product-price {
  font-size: 16px;
}

.sidebar-product-description {
  margin-top: 10px;
}

.sidebar-total {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.sidebar-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.checkout-button {
  background-color: #27d845;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #1aa82d;
}

.cancel-button {
  background-color: #d83727;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #a81d1d;
}

.quantity-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.quantity-control button {
  background-color: #27d845;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 10px;
}

.quantity-control button:hover {
  background-color: #1aa82d;
}

.quantity-control span {
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
  background-color: #f7f8fa;
  border-radius: 30px;
}

.image-item {
  flex: 1;
  max-width: 45%;
  margin: 20px;
  padding: 30px;
  background-color: #fafcff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  background-color: #032952;
}

.container-image {
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.text-container {
  text-align: left;
}

.image-description1 {
  margin: 5px 0;
  color: #0b1401;
  font-size: 14px;
  font-weight: bold;
}
.image-container1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 100px;
}

.image-item1 {
  display: flex;
  align-items: center;
  max-width: 45%;
  margin: 20px;
  padding: 30px;
  background-color: #1033be;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.container-image1 {
  width: 100%;
  max-width: 350px;
  height: auto;
  background-color: #1033be;
  border-radius: 10px;
  margin-right: 20px; /* Add margin to separate the image from other content */
}

.container-image {
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 10px;
 
}

.text-container {
  text-align: left;
  
}

.image-description {
  margin: 5px 0;
  color: #d7b440;
  
  font-size: 20px;
  
  font-family: 'Roboto Slab', 'Lucida Sans Typewriter', sans-serif;
  font-style:bold;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
 
}

.card {
  width: 300px;
  height: 250px;
  background-color: #9f0808;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.card-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.card:nth-child(1) {
  background-color: #137ff3;
}

.card:nth-child(2) {
  background-color: #90ee90;
}

.card:nth-child(3) {
  background-color: #f38bc4;
}

.custom-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  margin-right: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: var(--button-hover-color);
}
.home-container {
  background-color: var(--background-color);
  color: var(--text-color);
}
