.footer {
    background-color: #032952;
    color: rgb(10, 32, 178);
    padding: 20px;
    text-align: center;
    position: relative; 
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
  }
  
  .social-icons {
    margin-bottom: 20px;
  }
  
  .social-icons a {
    color: rgb(42, 26, 160);
    margin: 0 10px;
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #27d845;
  }
  
  .wallet-connect {
    margin-top: 10px;
  }
  
  .connect-button {
    background-color: #27d845;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .connect-button:hover {
    background-color: #1aa82d;
  }
  