

.App {
    text-align: center;
  }
  :root {
    --background-color: #f8f0f0;
    --text-color: #33c419;
    --primary-color: #032952;
    --button-hover-color: #27d845;
  }
  
  [data-theme="dark"] {
    --background-color: #6ed91c;
    --text-color: #f2f2f2;
    --primary-color: #27d845;
    --button-hover-color: #032952;
  }
  
  body, html, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .custom-button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    padding: 10px 20px;
    margin-right: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: var(--button-hover-color);
  }
  
  /* Add global styles for other components */
  .navbar, .footer, .home-content, .products-section, .checkout-form, .success-page {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  /* Add any other global styles needed for specific components */
  