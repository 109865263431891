/* CheckoutForm.css */

.checkout-form-container {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea,
  select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding and border don't affect width */
  }
  
  select {
    appearance: none; /* Remove default styling for dropdown arrow */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    padding-right: 30px; /* Adjust for the arrow */
  }
  
  textarea {
    height: 80px; /* Adjust height as needed */
  }
  
  .submit-button {
    background-color: #0275d8;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px; /* Adjust margin as needed */
  }
  
  .submit-button:hover {
    background-color: #1aa82d; /* Change color on hover */
  }
  .checkout-form {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  /* Ensure other styles use variables */
  